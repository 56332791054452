

@media (max-width: 991.98px) {
    h2 {
        font-size: 2.4rem;
    }
}

@media (max-width: 575.98px) {
    h2 {
        font-size: 2.2rem;
    }
}
