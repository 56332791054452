
.container-faq{

    .header-faq{
        h1{
            margin:auto;
            font-weight: 300;
            color: #555;
            font-size: 55px;
            margin-bottom: 0.8em;
        }
    }

    select{
        height:3em;
        margin-bottom:2.5em;
        border: none;
    }
   
}

.accordion-title {
    margin-bottom :0rem !important;
}