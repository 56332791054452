.member-portrait {
    border-radius: 50%;
    aspect-ratio: 1/1;
    width:50%;
    object-fit: cover;
}
.member-card {
    width:50%;
    margin:0 auto;
    text-align: center;
    
}

.member-position {
    font-weight: 230;
    letter-spacing: 0.2rem;
    color: #555;
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    
}

.member-name {
    font-weight: 230;
    letter-spacing: 0.1rem;
    color: #555;
    font-size: 2rem;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.active {
    background-color: #fff !important;
}

.carousel-control-next-icon {
    background-image: url('../../../images/right-arrow.svg') !important;
}

.carousel-control-prev-icon {
    background-image: url('../../../images/left-arrow.svg') !important;
}


// .right-margin {
//     margin-right: 1.5rem;
// }

.section-notre-equipe {
    padding: 10rem 0;
}


@media only screen and (max-width: 767.98px) {
    .member-position {
        font-size: 1.3rem;
    }

    .member-name {
        font-size: 1.3rem;
    }

    // .member-card {
    //     width: 30%;
    //     margin: 0 35%;
    //     text-align: center;
    // }
}

@media (max-width: 600px) {
    // .carousel-control-next-icon {
    //     display: none;
    // }

    // .carousel-control-prev-icon {
    //     display: none;
    // }

    // .member-card {
    //     width: 50%;
    //     margin: 0 25%;
    //     text-align: center;
    // }


    .section-notre-equipe {
        padding: 5rem 0;
    }
}



@media (max-width: 500px) {
    // .carousel-control-next-icon {
    //     display: none;
    // }

    // .carousel-control-prev-icon {
    //     display: none;
    // }

    // .member-card {
    //     width: 50%;
    //     margin: 0 25%;
    //     text-align: center;
    // }

    .member-position {
        font-size: 1rem;
    }
    
    .member-name {
        font-size: 1rem;
    }
    
}

