.div-card {
    background-color: #ffffff;
    position: relative;
    margin-left: 4%;
    width: 92%;
    height: 100%;
    height: 300px;
    margin-bottom: 3%;
    -webkit-box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.28);
    box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.28);
    overflow: hidden;
 
    .image-wrapper{
        align-self: center;
        text-align: center;
        height:75%;

    }
    .card-presentation {
        position: absolute;
        align-self: center;
        width:100%;
        height:100%;
        img {
            height: 100%;
            width: 100%;
            border: 1px;
            border-color: #70707000;
            border-style: solid;  
            object-fit: cover;
            object-position: 50% 20%;
        }

        h3,
        h4 {
            text-align: center;
            font-weight: 300;
        }
        h4 {
            font-family: "Roboto";
            opacity: 0.7;
        }
    }
    .card-text {
        top: 0px;
        border-radius: 15px;
        position: absolute;
        width: 100%;
        height: 100%;
        color: white;
        transition: .5s;
        transform-origin: bottom;
        transform: perspective(2000px) rotateX(-90deg);
        h3,
        h4 {
            text-align: center;
        }
        h3 {
            padding-top: 15px;
        }
        h4 {
            font-family: "Roboto";
            opacity: 0.7;
        }
        p {
            font-size: 13px;
            text-align: center;
            padding-left: 1em;
            padding-right: 1em;
            margin-bottom: 3%;
        }
        ul {
            list-style: none;
            padding-left: 1em;
            padding-right: 1em;
            font-size: 36px;
            li {
                text-align: center;
                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

.div-card:hover {
    .card-presentation {
        opacity: .5;
        transform: translateY(-30%);
        /*100%*/
    }
    .card-text {
        transform: perspective(2000px) rotateY(0deg);
    }
}

//ipad pro
@media (min-width: 1024px) and (max-width:1200px) {
   .card-text{
      p{ 
          font-size:10px !important;
          margin-bottom:1% !important;
      }
   }
}


//ipad
@media (min-width: 768px) and (max-width:1023px) {
   .card-text{
      p{
          font-size:12px !important;
          margin-bottom:0.5% !important;
      }
   }
}



@media (min-width: 300px) and (max-width:760px) {
    .div-card {
        //height: 350px;
    }
}