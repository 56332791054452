.about-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #000;
    font-size: 2.7rem;
    text-align: center;
    text-transform: uppercase;
}

.haut-de-page {
    box-sizing: border-box;
    margin: 0 !important;
    .background-a-propos {
        // background-color:red;
        margin: 0 !important;
        text-align: center;
        position: relative;
        height: 100vh;
        background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(../../../images/team_photo_distanciating.jpg);
        background-size: cover;
        h1,
        h3 {
            font-family: "Open Sans";
        }
        h1 {
            text-transform: uppercase;
            font-size: 2.7rem;
            position: absolute;
            letter-spacing: 0.2rem;
            top: 30%;
            text-align: center;
            font-weight: 300;
            width: 100%;
        }
        h3 {
            font-size: 2.7rem;
            position: absolute;
            top: 50%;
            text-align: center;
            width: 100%;
            font-weight: 400;
        }
    }
}

@media (max-width: 1024px) {
    .background-a-propos {
        background-position: center center;
        h1 {
            font-size: 2.7rem!important;
        }
        h3 {
            top: 40% !important;
            font-size: 40px!important;
        }
    }
}

@media (max-width: 780px) {
    .background-a-propos {
        background-position: center center;
        h1 {
            font-size: 2.3rem!important;
        }
        h3 {
            top: 40% !important;
            font-size: 30px!important;
        }
    }
}

@media (max-width: 600px) {
    .background-a-propos {
        background-position: center center;
        h1 {
            top: 28% !important;
            font-size: 2rem!important;
        }
        h3 {
            top: 40% !important;
            font-size: 20px!important;
        }
    }
}

@media (max-width: 360px) {
    .background-a-propos {
        h1 {
            top: 24% !important;
            font-size: 2rem!important;
        }
        h3 {
            font-size: 16px!important;
        }
    }
}

@media (max-width: 320px) {
    .background-a-propos {
        h1 {
            top: 24% !important;
            font-size: 1.6rem!important;
        }
        h3 {
            top: 35%!important;
            font-size: 16px!important;
        }
    }
}