@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

.hero-image-accueil {
  width: 60vw;
  height: auto;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

}

.headline {
  font-size: 1.8rem;
  width: 55%;
  font-weight: 245;
  text-transform: uppercase;
  line-height: 300%;
  letter-spacing: 0.2rem;
  

  
}
.logo-polyai {
  width :12rem;
  height: auto;
  margin-top: -2.5rem;
}
.hero-text {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);

}




.container-hero {
    position: relative;
    background-color: #f2f7fa;
    width: auto;
    height: 100vh;

}


@media only screen and (max-width: 1450px) {

  .headline {
    font-size: 1.5rem;

    
  
    
  }
  .logo-polyai {
    width :10rem;

  }


}
// Large devices (desktops, less than 1200px)
@media only screen and (max-width: 1199.98px) {


  .headline {
    font-size: 1.3rem;

  
    
  }

  .hero-text {
left:37%

}

  .logo-polyai {
    width :8rem;
    height: auto;
    margin-top: -1rem;
  }
}

@media only screen and (max-width: 991.98px) {
    .logo-polyai {
        width :6rem;
        height: auto;
  
      }

      .container-hero {
        height: 80vw;
    
    }

    .headline {
      font-size: 1rem;
  
    
      
    }
}
// Small devices (landscape phones, less than 768px)
@media only screen and (max-width: 767.98px) {

  .headline {
    font-size: 0.6rem;

  
    
  }


  .logo-polyai {
    width :6rem;
    height: auto;
  }

}

@media only screen and (max-width: 575.98px) {


    .container-hero {
        text-align: center;
    
    }
    .hero-image-accueil {
        width: 100vw;
        height: auto;
        position: static;
        transform: none;

      
      }
      
      .hero-text {
          position: static;
          transform: none;
          width: 80%;
          margin-left: 10%;

      

      
      }


      .headline {
        font-size: 1.2rem;
        width: 100%;
        font-weight: 245;
        text-transform: uppercase;
                letter-spacing: 0.1rem;
        
      
        
      }
      .logo-polyai {
        width :10rem;
        height: auto;
        margin-top: -1.5rem;
      }

      .container-hero {
        height: 160vw;
        padding: 1rem 0;
}
}



@media only screen and (max-width: 400px) {


    .headline {
      font-size: 1rem;
      width: 100%;
      font-weight: 245;
      text-transform: uppercase;
              letter-spacing: 0.1rem;
      
    
      
    }
    .logo-polyai {
      width :8rem;
      height: auto;
      margin-top: -1.5rem;
    }


}


@media only screen and (max-width: 330px) {


  .headline {
    font-size: 0.8rem;
    width: 100%;
    font-weight: 245;
    text-transform: uppercase;
            letter-spacing: 0.1rem;
    
  
    
  }
  .logo-polyai {
    width :6rem;
    height: auto;
    margin-top: -1.5rem;
  }


}

