.hero-image {
    position: relative;
    text-align: center;
    background-image: linear-gradient( rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../../../images/team-conf-pinto.jpg');
    background-position: 50% 80%;
    background-size: cover;
    background-attachment: fixed;
    color: black;
    margin: auto;
    width: 100%;
    min-height: 92vh;
}

.hero-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #000;
    font-size: 2.7rem;
    text-align: center;
    text-transform: uppercase;
}

.hero-heading:after {
    height: 0;
    margin: 0;
    width: 0;
}

.hero-description {
    font-size: 1rem;
    position: absolute;
    bottom: 8px;
    right: 8px;
    opacity: 0.5;
}

@media (max-width: 991.98px) {
    .hero-image {
        background-attachment: scroll;
    }
}

@media (max-width: 575.98px) {
    .hero-heading {
        color: #fff;
    }
    .hero-image {
        background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../images/events-mobile.jpeg');
    }
    .hero-description {
        display: none;
    }
}