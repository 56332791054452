.section-calendar {
    width: 100%;
    padding-top: 8rem;
    background-color: #f3f7f3;
}

.circle {
    text-align: center;
    line-height: 8rem;
    color: #fff;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    background-color: #ef4852;
    width: 8rem;
    height: 8rem;
    z-index: 100;
    margin: 4rem auto 12rem auto;
    position: relative;
}

.heading-calendar {
    margin-bottom: 4rem;
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #555;
    font-size: 2.7rem;
    text-align: center;
    text-transform: uppercase;
}

.heading-calendar:after {
    display: block;
    height: 0.1rem;
    background-color: #ef4852;
    content: '';
    width: 7rem;
    margin: 0 auto;
    margin-top: 2rem;
}

.details {
    font-weight: 600;
    width: 20%;
    margin-top: 1rem;
    margin-left: 40%;
    height: 1.2rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
}

.polyai-red {
    color: #ef4852;
}

.vertical-timeline-element-title,
.vertical-timeline-element-subtitle {
    text-align: center;
}

.collapse-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    .btn-sinscrire,
    .btn-facebook {
        align-content: center;
        margin-top: 5px;
        font-size: 16px;
        transition-duration: 0.2s;
        background-color: #EF485D;
        font-family: "Open Sans";
        border: none;
        width: 40%;
    }
    .btn-sinscrire:hover {
        background-color: #fa6477;
    }
    .btn-sinscrire:focus {
        background-color: #fa6477;
    }
    .btn-facebook {
        background-color: #555;
    }
}

@media only screen and (max-width: 1170px) {
    .circle {
        margin-bottom: 4rem;
        border-radius: 0;
        width: auto;
        height: 4rem;
        line-height: 4rem;
    }
}