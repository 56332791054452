.home-buttons-link {
    justify-self: center;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    border-radius: 10rem;
    background-color: #ef4852;
    color: #fff;
    transition: 0.3s;
}

.home-buttons-link:hover{
    text-decoration: none;
    color: black;
}
.home-buttons-wrapper{
    display: grid;
    align-items: center;
    justify-content: center;

}
