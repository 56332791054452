//PolyAI red: #ef4852
@import '~bootstrap/scss/bootstrap';

/*************************************/
/*             REUSABLE              */
/*************************************/

.row {
    padding: 0 5%;
    margin-bottom: 3rem;
}

/*************************************/
/*          GLOBAL SECTION           */
/*************************************/

.section-featured {
    width: 100%;
    padding: 8rem 0;
}

/*************************************/
/*              HEADING              */
/*************************************/

.section-featured-date {
    text-align: center;
    width: 70%;
    margin-left: 15%;
    font-size: 1.5rem;
    font-weight: 300;
    color: #555;
    margin-bottom: 5rem;
}

.section-featured-heading {
    margin-bottom: 2rem;
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #555;
    font-size: 2.7rem;
    text-align: center;
    text-transform: uppercase;
}


.section-featured-heading:after {
    display: block;
    height: 0.1rem;
    background-color: #ef4852;
    content: '';
    width: 7rem;
    margin: 0 auto;
    margin-top: 2rem;
}


/*************************************/
/*              TEXTBOX              */
/*************************************/

.section-featured-box:last-child {
    text-align: left;
    padding-left: 3%;
    position: relative;
    background-color: #f3f7f3;
}

.section-featured-text {
    font-size: 1.05rem;
    line-height: 180%;
    font-weight: 300;
    padding: 4rem 3rem 0 2rem;
     text-align: justify;
}

.section-featured-text:last-of-type {
    padding-top: 0;
    margin-bottom: 3.5rem;
}

.link {
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    display: inline-block;
    border-radius: 10rem;
    background-color: #ef4852;
    color: #fff;
    margin-left: 2rem;
}

.link:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bc3d52;
}

/*************************************/
/*              IMAGEBOX             */
/*************************************/

.section-featured-box:first-child {
    text-align: right;
}

.image-stack-container {
    width: 100%;
}

.image-stack-events {
    display: grid;
    grid-template-rows: 1fr;
    float:right;
    width: 500px;
    margin: 0 auto 2rem auto;
}

.image-stack__item--top {
    grid-column: 1 / span 10;
    grid-row: 1 / span 10;
}
.image-stack__item--bottom {
    grid-column: 2 / span 10;
    grid-row: 2 / span 10;
    width: 100%;
    height: auto;
}
.image-top-wrapper {
    background-color: #ef4852;
}
.image-top {
    width: 100%;
    height: auto;
    opacity: 0;
}
.image-bottom {
    width: 100%;
    height: auto;
}

/*************************************/
/*           MEDIA QUERIES           */
/*************************************/

@media only screen and (max-width: 1200px) {
    .link {
        margin-bottom: 4rem;
    }

    .section-featured-text {
        margin-bottom: 3.2rem;
    }
}

@media only screen and (max-width: 992px) {
    .section-featured-box:first-child {
        text-align: center;
        padding-right: 3%;
    }

    .image-stack-events {
        float: none;
    }
}

@media only screen and (max-width: 768px) {


    .image-stack-events{
        width: auto;
    }
    
}
