.container-communaute,
.container-etudiants,
.container-opportunite {
    margin: 0!important;
    padding: 0!important;
    box-sizing: border-box!important;
    height: 70vh;
    .back-color {
        position: absolute;
        height: 75%;
        width: 80%;
    }
    .image {
        position: absolute;
        background-size: cover;
        height: 75%;
        width: 80%;
    }
}

.container-communaute {
    .back-color {
        background-color: #BC3D52;
    }
    .image {
        background-image: url(../../../images/communaute2.jpg);
        left: 15%;
        top: 10%;
    }
}

.container-etudiants {
    .back-color {
        background-color: #9A808E;
        left: 20%;
    }
    .image {
        background-image: url(../../../images/communaute.jpg);
        right: 13%;
        top: 12%;
    }
}

.container-opportunite {
    .back-color {
        background-color: #A28681;
    }
    .image {
        background-image: url(../../../images/opportunites.jpeg);
        left: 15%;
        top: 10%;
    }
}

@media (min-width: 800px) and (max-width:1024px) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 60vh;
        .back-color {
            height: 85%;
            width: 85%;
        }
        .image {
            height: 85%;
            width: 85%;
        }
    }
}

@media (min-width: 600px) and (max-width:799px) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 60vh;
        .back-color {
            height: 0;
            width: 0;
        }
        .image {
            left: 10%;
            // height: 65%;
            width: 80%;
        }
    }
}

@media (min-width: 300px) and (max-width:599px) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 50vh;
        .back-color {
            height: 0;
            width: 0;
        }
        .image {
            left: 10%;
        }
    }
}

@media (min-width:300px) and (max-width:599px) and (min-height:800px) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 45vh;
        .back-color {
            left: 10%;
        }
        .image {
            left: 10%;
        }
    }
}

@media (min-height:380px) and (max-height:500px) and (orientation:landscape) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 60vh;
        .back-color {
            left: 15%;
            height: 80%;
            width: 70%;
        }
        .image {
            left: 15%;
            height: 85%;
            width: 70%;
        }
    }
}

@media (min-height:1px) and (max-height:375px) and (orientation:landscape) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 85vh;
        .back-color {
            left: 20%;
            height: 80%;
            width: 55%;
        }
        .image {
            left: 20%;
            height: 80%;
            width: 55%;
        }
    }
}

@media (min-width: 1px) and (max-width:299px) {
    .container-communaute,
    .container-etudiants,
    .container-opportunite {
        height: 35vh;
        .back-color {
            left: 10%;
        }
        .image {
            left: 10%;
        }
    }
}