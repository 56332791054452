.home-partners-wrapper {
    display: grid;
    margin-left: 20vh;
    margin-right: 20vh;
    grid-gap: 2vh;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    margin-bottom: 5rem;
    padding-bottom: 6vh;
    align-items: center;
}

.section-partenaires {
    padding: 10rem 0;
    background-color: #f2f7fa;
}





.home-partners-image {
    width: 80%;
}

.home-partners-title {
    text-align: center;
    margin-bottom: 6vh;
    margin-top: 6vh;
}

.some-bullshit {
    width: 10%;
    border: 4px red;
    justify-self: center;
    color: red;
}

//PolyAI red: #ef4852
@import '~bootstrap/scss/bootstrap';

/*************************************/
/*              TEXTBOX              */
/*************************************/

.box:last-child {
    text-align: left;
    padding-left: 3%;
    position: relative;
    background-color: #fff;
}

.text {
    font-size: 0.9rem;
    line-height: 180%;
    font-weight: 300;
    padding: 4rem 3rem 0 2rem;
    text-align: justify;
}

.text:last-of-type {
    padding-top: 0;
    margin-bottom: 2.2rem;
}

.link {
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    display: inline-block;
    border-radius: 10rem;
    background-color: #ef4852;
    color: #fff;
    margin-left: 2rem;
    margin-bottom: 4rem;

}

.link:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bc3d52;
}

/*************************************/
/*              IMAGEBOX             */
/*************************************/

.box:first-child {
    text-align: right;
}

.image-stack-container {
    width: 100%;
}

.image-stack {
    display: grid;
    float: right;
    grid-template-rows: 1fr;
    width: 500px;
    margin: 0 auto 2rem auto;
}

.image-stack__item--top {
    grid-column: 1 / span 10;
    grid-row: 1 / span 10;
}
.image-stack__item--bottom {
    grid-column: 2 / span 10;
    grid-row: 2 / span 10;
    width: 100%;
    height: auto;
}
.image-top-wrapper {
    background-color: #ef4852;
}
.image-top {
    width: 100%;
    height: auto;
    opacity: 0;
}
.image-bottom {
    width: 100%;
    height: auto;
}

/*************************************/
/*           MEDIA QUERIES           */
/*************************************/

@media only screen and (max-width: 1200px) {


    .text {
        margin-bottom: 3.2rem;
    }
}



@media only screen and (max-width: 991.98px) {
    .home-partners-wrapper {
        margin-left: 20vh;
        margin-right: 20vh;
        grid-gap: 7vh;
    }

    .box:first-child {
        text-align: center;
        padding-right: 3%;
    }

    .image-stack {
        float: none;
    }
    .home-partners-wrapper {
        grid-template-columns: 1fr;
    }
}
@media only screen and (max-width: 768px) {



    .section-partenaires {
        padding: 5rem 0;
    }

    .image-stack {
        width: auto;
    }
    
}


@media only screen and (max-width: 600px) {
    .home-partners-wrapper {
        margin-left: 10vh;
        margin-right: 10vh;
    }


    .section-partenaires {
        padding: 5rem 0;
    }
    
}

@media only screen and (max-width: 400px) {
    .home-partners-wrapper {
        margin-left: 6vh;
        margin-right: 6vh;
    }

}