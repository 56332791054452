.heading {
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #555;
    font-size: 2.7rem;
    text-transform: uppercase;
    margin: 0 auto;
}

.container-mission {
    padding: 8rem 0;
    .row-communaute {
        margin-bottom: 4em;
    }
    .row-etudiants {
        margin-bottom: 6em;
    }
    .header-mission {
        h1 {
            font-weight: 300;
            color: #555;
            font-size: 60px;
            margin: auto;
            margin-bottom: 0.2em;
        }
        h2 {
            font-weight: 400;
            font-size: 26px;
            margin-bottom: 1.3em;
        }
    }
    .communaute-text,
    .etudiants-text,
    .opportunite-text {
        .heading {
            font-size: 2.2rem
        }
        .heading:after {
            margin-bottom: 2rem;
        }
        // h2 {
        //     font-size: 36px;
        //     margin-bottom: 0.4em;
        //     font-weight: 500;
        // }
        p {
            font-size: 1.2rem;
            line-height: 180%;
            font-weight: 300;
            text-align: justify;
        }
        // background-color: #f3f7f3;
        padding: 3rem 3rem;
        @media (max-width: 575.98px) {
            .heading {
                font-size: 1.6rem;
            }
        }
    }
}

@media (max-width: 600px) {
    .header-mission {
        h1 {
            font-size: 50px!important;
            margin-bottom: 0.2em;
        }
        h2 {
            font-weight: 400;
            font-size: 22px!important;
            margin-bottom: 1.3em;
        }
    }
    .communaute-text,
    .etudiants-text,
    .opportunite-text {
        // h2 {
        //     font-size: 30px!important;
        //     margin-bottom: 0.4em;
        //     font-weight: 500;
        // }
        // p {
        //     font-size: 20px!important;
        //     text-align: justify;
        // }
    }
}

@media (max-width: 300px) {
    .header-mission {
        h1 {
            font-size: 40px!important;
            margin-bottom: 0.2em;
        }
        h2 {
            font-weight: 400;
            font-size: 20px!important;
            margin-bottom: 1.3em;
        }
    }
    .communaute-text,
    .etudiants-text,
    .opportunite-text {
        h2 {
            font-size: 24px!important;
            margin-bottom: 0.4em;
            font-weight: 500;
        }
        p {
            font-size: 18px!important;
            word-spacing: -3px;
            text-align: justify;
        }
    }
}