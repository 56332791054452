.heading {
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #555;
    font-size: 2.7rem;
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: 991.98px) {
    .heading {
        font-size: 2.4rem;
    }
}

@media (max-width: 575.98px) {
    .heading {
        font-size: 1.8rem;
    }
    .heading:after {
        margin-bottom: 4rem;
    }

    .heading {
        margin-top: 4rem;
  
    }
}

.heading:after {
    display: block;
    height: 0.1rem;
    background-color: #ef4852;
    content: '';
    width: 7rem;
    margin: 2rem auto 5rem auto;
}



