Form {
    //background-color:red;
    box-sizing: border-box;
    .a-propos-label {
        font-family: "Open Sans";
        font-size: 14px;
    }
    input,
    select,
    textarea {
        margin-bottom: 1em;
        box-sizing: border-box;
    }
    textarea {
        margin-bottom: 2em;
    }
    .btn-a-propos-submit {
        font-size: 16px;
        color: #FFFFFF;
        transition-duration: 0.2s;
        // border-radius: 1.25em;
        background-color: #EF485D;
        font-family: "Open Sans";
        border: none;
    }
    .btn-a-propos-submit:hover {
        background-color: #f0102c;
    }
    .btn-a-propos-submit:focus {
        background-color: #f0102c;
    }
    .error-required {
        border-color: #f0102c;
    }
    .error-message {
        color: #f0102c;
    }
}