

.col-formulaire{
    //background-color: green;
    h1{
        margin-bottom:4%;
        font-weight: 300;
        color: #555;
        font-size: 60px;
    }
}

.col-addInfo{
   // background-color:blue;
   margin-top:10%;
   
}

.container-down {
    padding: 8rem 0;
}