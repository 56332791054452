.projects-page-wrapper {
    .text-hero {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 200;
        letter-spacing: 0.2rem;
        color: #000;
        font-size: 2.7rem;
        text-align: center;
        text-transform: uppercase;
    }
    .hero {
        width: 100%;
        height: 92vh;
        background-size: cover;
        background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4)), url(../../images/projects3.jpeg);
    }
    .our-projects {

        padding: 7rem 0;
        .heading {
            font-weight: 200;
            letter-spacing: 0.2rem;
            color: #555;
            font-size: 2.7rem;
            text-transform: uppercase;
            margin: 0 auto;
        }




@media only screen and (max-width: 480px) {


.heading {
    font-size: 2rem;
                }
        
            
            

}

        
    }
    .center {
        justify-content: center;
    }
}


