.home-events-wrapper {
    display: grid;
    margin-left: 15vh;
    margin-right: 15vh;
    grid-gap: 3vh;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    text-align: left;
    color: #555;
    padding-bottom: 6vh;
}

.section-events {
    background-color: #f3f7f3;
    padding: 10rem 0;
}
.home-events-image {
    width: 100%;
    height: 35%;
    border: 1px solid #ef485d;
    object-fit:cover;
}
.home-events-center{
    object-position: 50% 50%;

}
.home-events-right-bottom{
    object-position: 100% 78%;


}


@media only screen and (max-width: 991.98px) {
    .home-events-wrapper {
        grid-template-columns: 1fr;
    }
    .home-events-image{
        width: 100%;
        height: 35%;
        border: 1px solid #ef485d;
        object-fit:cover;    
    }
    .home-events-right-bottom{
        object-position: 50% 50%;
    }
    
}

@media only screen and (max-width: 600px) {
    .section-events {
        padding: 5rem 0;
    }
    .home-events-wrapper {
        margin-left: 5vh;
        margin-right: 5vh;
    }
}


.home-events-title {
    text-align: center;
    margin-bottom: 6vh;
    margin-top: 6vh;
}
.home-events-wrapper h4 {
    font-weight: 200;
    letter-spacing: 0.2rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 2rem;
}


.home-events-wrapper p {
    font-weight: 250;
    text-align: justify;
}
