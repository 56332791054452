// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
.card-wrapper {
    width: 100%;
    border-radius: 5px;
    -webkit-box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.28);
    box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.28);
    padding: 1rem;
    .card-hero {
        width: 100%;
        background-size: cover;
        // height: 300px;
    }

    padding: 3rem;
    p {
        margin-top: 2.5rem;
        text-align: justify;
        font-weight: 250;
        color: #555;
    }
    li {
        text-align: justify;
        font-weight: 250;
        color: #555;
    }

    h2 {
        font-weight: 200;
        letter-spacing: 0.2rem;
        color: #555;
        font-size: 1.7rem;
        text-transform: uppercase;
        margin: 0 auto 2.5rem 0;
    }

    h4 {
        font-weight: 200;
        letter-spacing: 0.2rem;
        color: #555;
        font-size: 1.7rem;
        text-transform: uppercase;
        margin: 0 auto 1rem 0;
    }



}



@media only screen and (max-width: 480px) {


    .card-wrapper {


    padding: 1.7rem;
    p {
font-size: .7rem;
margin-top: 1.5rem;
    }
    li {
font-size: 0.7rem;
    }

    h2 {
font-size: 1rem;
margin: 0 auto 0.5rem 0;
    }

    h4 {
        font-size: 1rem;
        margin: 0 auto 0.5rem 0;

    }

}

}