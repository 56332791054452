@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
.section-qui-sommes-nous {
    position: relative;
    width: auto;
    height: 100vh;
    padding: 10rem 0;
    overflow: hidden;
}

.qui-sommes-nous-image {
    width: auto;
    height: 80vh;
    position: absolute;
    right: 0;
}

.text-grid {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.text-grid div:nth-child(1) {
    font-family: 'Open Sans';
    font-size: 4.4rem;
    text-transform: uppercase;
    letter-spacing: -0.2rem;
    grid-column: 1/8;
    white-space: nowrap
}

.text-grid div:nth-child(2) {
    grid-column: 5/9;
}

.text-grid div:nth-child(3) {
    font-family: Open Sans;
    font-size: 4.4rem;
    text-transform: uppercase;
    color: #ef485d;
    letter-spacing: -0.2rem;
    grid-column: 1/2;
    margin-top: -1rem;
    margin-right: 3.2rem;
}

.text-grid div:nth-child(4) {
    grid-column: 2/9;
    width: 80%;
    font-size: 1.5rem;
    text-align: justify;
}

@media only screen and (max-width: 1600px) {
    .text-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .text-grid div:nth-child(1) {
        font-size: 4.2rem;
        grid-column: 1/5;
    }
    .text-grid div:nth-child(2) {
        grid-column: 5/7;
    }
    .text-grid div:nth-child(3) {
        font-size: 4.2rem;
        grid-column: 1/2;
        margin-right: 1.5rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 1.3rem;
        padding-left: 0;
    }
}

// Large devices (desktops, less than 1200px)
@media only screen and (max-width: 1199.98px) {
    .text-grid div:nth-child(3) {
        margin-top: -0.7rem;
    }
    .text-grid {
        left: 40%
    }
}

// Medium devices (tablets, less than 992px)
@media only screen and (max-width: 991.98px) {
    .text-grid div:nth-child(1) {
        font-size: 3.3rem;
    }
    .text-grid div:nth-child(3) {
        font-size: 3.3rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 1rem;
        padding-top: 0;
    }
    .qui-sommes-nous-image {
        height: 50vw;
        top: 20vw;
    }
    .section-qui-sommes-nous {
        height: 80vw;
    }
    .text-grid {
        left: 40%;
    }
}

// Small devices (landscape phones, less than 768px)
@media only screen and (max-width: 767.98px) {
    .text-grid div:nth-child(1) {
        font-size: 2.5rem;
    }
    .text-grid div:nth-child(3) {
        font-size: 2.5rem;
        margin-right: 1rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 0.8rem;
        margin-top: -0.1rem;
    }
}

@media only screen and (max-width: 600px) {
    .text-grid div:nth-child(1) {
        font-size: 2rem;
        letter-spacing: -0.1rem;
    }
    .text-grid div:nth-child(3) {
        font-size: 2rem;
        margin-top: -0.5rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 0.6rem;
    }
    .qui-sommes-nous-image {
        height: 70vw;
        top: 20vw;
    }
    .section-qui-sommes-nous {
        height: 110vw;
    }
    .text-grid {
        left: 38%;
    }
}

@media only screen and (max-width: 480px) {
    .text-grid div:nth-child(1) {
        font-size: 1.3rem;
    }
    .text-grid div:nth-child(3) {
        font-size: 1.3rem;
        margin-top: -0.5rem;
        margin-right: 0.7rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 9px;
        width: 90%;
    }
}

@media only screen and (max-width: 400px) {
    .text-grid div:nth-child(1) {
        font-size: 1rem;
    }
    .text-grid div:nth-child(3) {
        font-size: 1rem;
        margin-right: 0rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 9px;
    }
}

@media only screen and (max-width: 300px) {
    .text-grid div:nth-child(1) {
        font-size: 0.8rem;
    }
    .text-grid div:nth-child(3) {
        font-size: 0.8rem;
    }
    .text-grid div:nth-child(4) {
        font-size: 7px;
    }
}