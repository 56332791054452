@import 'src/styles/constants';
.custom-nav-wrapper {
    justify-items: center;
    display: grid;
    grid-template-areas: "brand routes" "newsletter newsletter";
    background-color: #ef485d;
    height: $navbar_height;
    padding: 0px;
    .custom-navbar-brand {
        grid-area: brand;
        padding: 0px;
        padding-left: 1vh;
    }
    .custom-navbar-routes {
        grid-area: routes;
        padding-right: 2vh;
    }
    .custom-navbar-newsletter {
        grid-area: newsletter;
        background-color: white;
        width: 100%;
        text-align: center;
        a {
            color: black;
        }
    }

    .language-button,
    .language-button:hover,
    .btn-secondary:not(:disabled):not(.disabled):active{
        background: none;
        border: 0px;
        padding: 0px;
        box-shadow:none;
    }
}

// necessary to overwrite react-strap bs padding
.navbar {
    padding: 0px !important;
}

.white-background {
    background-color: white;
}

.custom-nav-link, .language-button {
    text-decoration: none;
    display: inline;
    font-size: calc(4px + 2vmin);
    color: white;
    padding-right: 2vh;
    transition: 0.3s;
    opacity: 1;
}


.custom-nav-link:hover {
    opacity: 0.6;
}

.custom-navbar-selected {
    color: #000;
    font-style: bold;
}

body {
    padding-top: $navbar_height;
}

.mobile-brand {
    margin-top: 0rem;
    margin-left: 1rem;
}

// .mobile-toggler{
//     margin-top: 1rem;
// }
.mobile-collapse {
    margin-left: 2rem;
    margin-top: 1rem;
}

.custom-nav-link-mobile {
    text-decoration: none;
    display: inline;
    font-size: calc(8px + 2vmin);
    color: black;
    padding-right: 2vh;
    transition: 0.3s;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.7);
}

.custom-nav-link-logo {
    width: calc(80px + 2vmin);
    height: auto;
}