.container-collaborator {
    h1 {
        margin: auto;
        font-weight: 300;
        color: #555;
        font-size: 60px;
        margin-bottom: 0.8em;
    }
    .center {
        justify-content: center;
    }
    .purple {
        background: #9A808E;
        border: none;
    }

    padding: 8rem 0;
    #collaborator-row{
        .img{
            object-fit: scale-down;
        
        }
    }
}

    .heading {
        font-weight: 200;
        letter-spacing: 0.2rem;
        color: #555;
        font-size: 2.7rem;

        text-transform: uppercase;
        margin: 0 auto;
    }
