
.container-addinfo{

    h1{
        font-size:26px;
        font-weight:700;
    }

    .first-list{
        list-style:none;
        padding:0;
    }

     .first-list li:not(#last-li-element){
        margin-bottom:1em;
    }

    .second-list{
        padding:0;
        width:100%;
        list-style:none;

        li{
            a{
                color:black;
                text-decoration:none;
            }
        }
    }

    .second-list li{
        display:inline;
        font-size: 30px;
    }

     .second-list li:not(#first-li-element){
        margin-left : 0.75em;
    }

     button{
       margin-top:0.5em;
       font-weight:bold;
       font-size:14px;
       border-width:thin;
       border-color: #EF485D;
       color: #EF485D;
       border-radius:1.25em;
       background-color:#F0F0EE;
       transition-duration: 0.2s;
   }

   button:hover {
       background-color: #EF485D ; 
       color: #F0F0EE;
   }

}