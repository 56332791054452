.container-team {
    h1 {
        margin: auto;
        font-weight: 300;
        color: #555;
        font-size: 60px;
        margin-bottom: 0.8em;
    }
    .center {
        justify-content: center;
    }
    .pink {
        background: #EF485D;
        border: none;
    }
    padding: 8rem 0;
    // background-color: #f3f7f3;
    width:100%
}

.heading {
    font-weight: 200;
    letter-spacing: 0.2rem;
    color: #555;
    font-size: 2.7rem;
    text-transform: uppercase;
    margin: 0 auto;
}